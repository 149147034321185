<template>
  <div>
    <!-- <div>
      <loading :active.sync="isLoader" loader="spinner" color="#36304a" :width="70"></loading>
    </div> -->
  
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-card-header class="mb-3">
      <b-row>
        <b-col> <i class="icon-menu mr-1"></i>Novo Account Report</b-col>
      </b-row>
    </b-card-header>
    <b-card>
      <div class="row">
        <b-col sm="3">
          <b-form-group>
            <label for="from-date">From Date :</label>
            <b-form-input
              type="date"
              v-model="detailAccountsData.FromDate"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col sm="3">
          <b-form-group>
            <label for="to-date">To Date :</label>
            <b-form-input
              type="date"
              v-model="detailAccountsData.ToDate"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group>
            <label for="from-date">Select Cinema Name</label>
            <b-form-select
              v-model="detailAccountsData.selectedCinema"
              id="cinemas"
              :plain="true"
              text-field="CinemaName"
              value-field="CinemaID"
              :options="$store.getters['common/getAllCinemas']"
              @change="getMovie"
            >
              <template slot="first">
                <option :value="'0'" disabled>-- Select Cinema --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group>
            <label for="movie_name">Movie :</label>
            <b-form-select
              required
              v-model="detailAccountsData.selectedMovie"
              id="movie_name"
              :plain="true"
              text-field="Title"
              value-field="ID"
              :options="MovieList"
            >
              <template slot="first">
                <option :value="'0'" disabled>-- Select Movie --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </div>
      <div class="row">
        <b-col sm="12" class="text-center">
          <b-button
            class="ml-2"
            type="submit"
            size="sm"
            variant="primary"
            @click="getNovoAccountsReport"
            >Search</b-button
          >
          <b-button
            class="ml-2"
            type="submit"
            size="sm"
            variant="primary"
            @click.prevent="resetForm"
            >Reset</b-button
          >
          <b-button
            class="ml-2"
            type="submit"
            size="sm"
            variant="primary"
            @click="ExportNovoAccountsExcel"
            >Export to Excel</b-button
          >
        </b-col>
      </div>
    </b-card>
    <hr />
    <b-card>
         <div style="width: 100%;height: 100%;position: absolute;background: white;z-index: 1000;" v-if="isLoader"><CircleLoader  />
    </div>
      <v-client-table
        :data="data1"
        :columns="columns1"
        :options="options1"
      ></v-client-table>
      <div class="table_class">
        <table class="table table-bordered w-100">
          <thead style="color: white">
            <tr>
              <th>SN.</th>
              <th>Cinema Name</th>
              <th>Movie</th>
              <th>Transaction Date</th>
              <th>Movie Date</th>
              <th>Total Transactions</th>
              <th>Payment Type</th>
              <th>Total Tickets</th>
              <th>Paid Amount</th>
              <th colspan="4">Paid to Novo</th>
              <th colspan="7">Paid to Wanasa Time</th>
              <th>Paid to Payment Gateway</th>
              <th>Failed Transactions</th>
              <th>Failed Tickets</th>
              <th>Ticket Transaction Amount</th>
            </tr>
            <tr>
              <th colspan="9"></th>
              <th>Basic Ticket Price(BTP) Incl. VAT</th>
              <th>Bank Charges(BC) on BTP</th>
              <th>VAT on BC on BTP</th>
              <!-- Novo -->
              <!-- <th>Service Charges(SC)</th>
              <th>VAT on SC</th>
              <th>Bank Charges(BC) on SC</th>
              <th>VAT on BC on SC</th> -->

              <!-- <th>SCB Convenience Fee</th>
              <th>VAT(10%) on SCB Convenience Fee</th> -->
              
              <th>Transferable to Novo</th>
              
              <th>Service Charges(SC)</th>
              <th>VAT on SC</th>
              <th>Bank Charges(BC) on SC</th>
              <th>VAT on BC on SC</th>
              <th>BC on BTP (Novo Share)</th>
              <th>VAT on BC on BTP (Novo Share)</th>

              
              <th>Total Amount</th>
              <!-- Novo -->
              <th colspan="4"></th>
            </tr>
          </thead>

          <tbody v-if="displayedRecords.length > 0">
            <tr v-for="(item, index) in displayedRecords" :key="index">
              <td>{{ item.Sr }}</td>
              <td>{{ item.CinemaName }}</td>
              <td>{{ item.MovieName }}</td>
              <td>{{ item.TransactionDate }}</td>
              <td>{{ item.ShowDate }}</td>
              <td>{{ item.TransactionCount }}{{ getUpdate }}</td>
              <!-- <td>{{ ToT_TransactionCount(index) }}</td> -->
              <td>{{ item.PaymentType }}</td>
              <td>{{ item.NoofTicket }}</td>
              <td>{{ item.PaidAmount }}</td>
              <td>{{ item.BaseTicketPriceInclVAT }}</td>
              <td>{{ item.BCONBTP }}</td>
              <td>{{ item.VATONBCONBTP }}</td>
<!-- 
              <td>{{ item.service_charges_novo }}</td>
              <td>{{ item.vat_service_charges_novo }}</td>
              <td>{{ item.bc_service_charges_novo }}</td>
              <td>{{ item.vat_bc_service_charges_novo }}</td> -->

              <td>{{ item.TotalAmount }}</td>
              <td>{{ item.service_charge_telebu }}</td>
              <td>{{ item.vat_service_charge_telebu }}</td>
              <td>{{ item.bc_service_charge_telebu }}</td>
              <td>{{ item.vat_bc_service_charge_telebu }}</td>
              <td>{{ item.BCONBTP }}</td>
              <td>{{ item.VATONBCONBTP }}</td>

              <!-- <td>{{ item.bconscb_telebu }}</td>
              <td>{{ item.vatonbconscb_telebu }}</td> -->

              <td>{{ item.total_amount_telebu }}</td>
              <td>{{ item.TotalBankCharges }}</td>
              <td>{{ item.FailedTransactions }}</td>
              <td>{{ item.FailedTickets }}</td>
              <td>{{ item.FailedAmount }}</td>
            </tr>
            <tr>
              <td colspan="5"><b>Total</b></td>
              <td>{{ TotalTransactionCount }}</td>
              <td></td>
              <td>{{ TotalNoofTicket }}</td>
              <td>{{ TotalPaidAmount.toFixed(3) }}</td>
              <td>{{ TotalBTP.toFixed(3) }}</td>
              <td>{{ TotalBCONBTP.toFixed(3) }}</td>
              <td>{{ TotalVATONBCONBTP.toFixed(3) }}</td>

              <!-- <td>{{ Totalservice_charges_novo.toFixed(3) }}</td>
              <td>{{ Totalvat_service_charges_novo.toFixed(3) }}</td>
              <td>{{ Totalbc_service_charges_novo.toFixed(3) }}</td>
              <td>{{ Totalvat_bc_service_charges_novo.toFixed(3) }}</td> -->

              <td>{{ TotalTotalAmount.toFixed(3) }}</td>

              <td>{{ Totalservice_charge_telebu.toFixed(3) }}</td>
              <td>{{ Totalvat_service_charge_telebu.toFixed(3) }}</td>
              <td>{{ Totalbc_service_charge_telebu.toFixed(3) }}</td>
              <td>{{ Totalvat_bc_service_charge_telebu.toFixed(3) }}</td>

              <td>{{ TotalnovoBCONBTP.toFixed(3) }}</td>
              <td>{{ TotalnovoVATONBCONBTP.toFixed(3) }}</td>


              <td>{{ Totaltotal_amount_telebu.toFixed(3) }}</td>
              <td>{{ TotalTotalBankCharges.toFixed(3) }}</td>
              <td>{{ TotalFailedTransactions.toFixed(3) }}</td>
              <td>{{ TotalFailedTickets.toFixed(3) }}</td>
              <td>{{ TotalFailedAmount.toFixed(3) }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="12"><center>Data Not Found!</center></td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :records="records.length"
        v-model="page"
        :per-page="perPage"
        @paginate="callback"
      >
      </pagination>
     
      <hr />
    </b-card>
  </div>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import AccountReportService from "@/services/AccountReportService";
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
import CircleLoader from "../../SchedulesDetails/Circle.vue";

// jspdf-autotable
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName, WANASHAdashboardName } from "@/helper/constant";
import XLSX from "xlsx";
import { pdfFillColor } from "@/helper/constant";
import Pagination from "vue-pagination-2";
import vSelect from "vue-select";

export default {
  name: "NovoAccountsReport",
  components: {
    Event,
    cSwitch,
    vSelect,
    Pagination,
    // Loading,
    CircleLoader
  },
  watch: {},
  data: function () {
    return {
      isLoader: false,
      TotalTransactionCount: 0,
      TotalNoofTicket: 0,
      TotalPaidAmount: 0,
      TotalBTP: 0,
      TotalBCONBTP: 0,
      TotalVATONBCONBTP: 0,
      TotalSCBConveniencefee: 0,
      TotalVATONSCBConveniencefee: 0,
      TotalTotalAmount: 0,

      Totalservice_charges_novo: 0,
      Totalvat_service_charges_novo: 0,
      Totalbc_service_charges_novo: 0,
      Totalvat_bc_service_charges_novo: 0,

      Totalservice_charge_telebu: 0,
      Totalvat_service_charge_telebu: 0,
      Totalbc_service_charge_telebu: 0,
      Totalvat_bc_service_charge_telebu: 0,

      TotalnovoBCONBTP: 0,
      TotalnovoVATONBCONBTP: 0,

      Totalbconscb_telebu: 0,
      Totalvatonbconscb_telebu: 0,

      Totaltotal_amount_telebu: 0,
      TotalTotalBankCharges: 0,
      TotalFailedTransactions: 0,
      TotalFailedTickets: 0,
      TotalFailedAmount: 0,
      page: 1,
      perPage: 10,
      records: [],
      counter: 21,
      MovieList: [],
      detailAccountsData: {
        FromDate: "",
        ToDate: "",
        selectedCinema: 0,
        selectedPaymentMode: "",
        selectedMovie: 0,
      },
      paymentList: ["All", "Debit Card", "Credit Card", "SCB"],
      columns: [
        "Sr",
        "CinemaName",
        "MovieName",
        "TransactionDate",
        "ShowDate",
        "TransactionCount",
        "PaymentType",
        "NoofTicket",
        "PaidAmount",
        "BaseTicketPriceInclVAT",
        "BCONBTP",
        "VATONBCONBTP",

        // "service_charges_novo",
        // "vat_service_charges_novo",
        // "bc_service_charges_novo",
        // "vat_bc_service_charges_novo",

        // "SCBConveniencefee",
        // "VATONSCBConveniencefee",
        "TotalAmount",

        "service_charge_telebu",
        "vat_service_charge_telebu",
        "bc_service_charge_telebu",
        "vat_bc_service_charge_telebu",

        "BCONBTP",
        "VATONBCONBTP",

        // "bconscb_telebu",
        // "vatonbconscb_telebu",

        "total_amount_telebu",
        "TotalBankCharges",
        "FailedTransactions",
        "FailedTickets",
        "FailedAmount",
      ],
      data: [],
      finalArr: [],
      ExcelArr: [],
      TotalDetails: [],
      options: {
        headings: {
          Sr: "SN",
          CinemaName: "Cinema Name",
          MovieName: "Movie",
          TransactionDate: "Transaction Date",
          ShowDate: "Movie Date",
          TransactionCount: "Total Transactions",
          PaymentType: "Payment Type",
          NoofTicket: "Total Tickets",
          PaidAmount: "Paid Amount",
          BaseTicketPriceInclVAT: "Basic Ticket price (BTP)",
          BCONBTP: "Bank Charges(BC) on BTP",
          VATONBCONBTP: "VAT on BC on BTP",

          // service_charges_novo: "Service Charges Novo",
          // vat_service_charges_novo: "VAT on SC Novo",
          // bc_service_charges_novo: "Bank Charges(BC) on SC Novo",
          // vat_bc_service_charges_novo: "VAT on BC on SC Novo",

          // SCBConveniencefee: "SCB Convenience Fee",
          // VATONSCBConveniencefee: "VAT(10%) on SCB Convenience Fee",
          TotalAmount: "Transferable to Novo",

          service_charge_telebu: "Service Charges Wanasa Time",
          vat_service_charge_telebu: "VAT on SC Wanasa Time",
          bc_service_charge_telebu: "Bank Charges(BC) on SC Wanasa Time",
          vat_bc_service_charge_telebu: "VAT on BC on SC Wanasa Time",

          BCONBTP: "BC on BTP (Novo Share)",
          VATONBCONBTP:
            "VAT on BC on BTP (Novo Share)",

          // bconscb_telebu: "BC ON SCB CONVENIENCE FEE",
          // vatonbconscb_telebu: "VAT ON BC ON SCB CONVENIENCE FEE",

          total_amount_telebu: "Wanasa Time Total Amount",
          TotalBankCharges: "Paid to Payment Gateway",
          FailedTransactions: "Failed Transactions",
          FailedTickets: "Failed Tickets",
          FailedAmount: "Ticket Transaction Amount",
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },

      columns1: [
        "TotalAmount",
        "TotalTickets",
        "NovoAmount",
        "PGAmount",
        "TelebuAmount",
        "TelebuBankCharges",
      ],
      data1: [],
      options1: {
        headings: {
          TotalAmount: "Total Amount (BHD)",
          TotalTickets: "Total Tickets",
          NovoAmount: "Novo Amount(BHD)",
          PGAmount: "Payment Gateway Amount(BHD)",
          TelebuAmount: "Wanasa Time Amount(BHD)",
          TelebuBankCharges: "Wanasa Time Bank Charges",
        },

        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },

      //Book New Transcation

      useVuex: false,
      theme: "bootstrap4",
      theme1: "bootstrap4",
      theme2: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
    };
  },
  beforeMount() {
    let payload = {};
    // alert(this.secureStore.get("TTURO"));
    if (this.secureStore.get("TTURO") == "Admin" || this.secureStore.get("TTURO") =="Finance") {
      payload = {
        OrgID: 8,
      };
    }
    this.$store.dispatch("common/setAllCinemas", payload);
    // this.$store.dispatch("common/setAllCinemas");
    this.getFromToDate();
    this.getNovoAccountsReport();
  },
  mounted() {},
  methods: {
    getMovie() {
      if (this.detailAccountsData.selectedCinema) {
        let payload = {
          CinemaID: this.detailAccountsData.selectedCinema,
        };
        this.MovieList = [];
        AccountReportService.getMoviesByScreenID(payload)
          .then((response) => {
            const { data } = response;
            if (data.MovieList.length > 0) {
              this.MovieList = data.MovieList;
            } else {
              this.showMessageSnackbar(data.Message);
            }
          })
          .catch((error) => {
            console.log("Catch on AddUser-getRoles Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }
    },
    callback: function (page) {
      this.TotalTransactionCount = 0;
      this.TotalNoofTicket = 0;
      this.TotalPaidAmount = 0;
      this.TotalBTP = 0;
      this.TotalBCONBTP = 0;
      this.TotalVATONBCONBTP = 0;
      this.TotalSCBConveniencefee = 0;
      this.TotalVATONSCBConveniencefee = 0;
      this.TotalTotalAmount = 0;

      this.Totalservice_charges_novo = 0;
      this.Totalvat_service_charges_novo = 0;
      this.Totalbc_service_charges_novo = 0;
      this.Totalvat_bc_service_charges_novo = 0;

      this.Totalservice_charge_telebu = 0;
      this.Totalvat_service_charge_telebu = 0;
      this.Totalbc_service_charge_telebu = 0;
      this.Totalvat_bc_service_charge_telebu = 0;

      this.TotalnovoBCONBTP = 0;
      this.TotalnovoVATONBCONBTP = 0;

      this.Totalbconscb_telebu = 0;
      this.Totalvatonbconscb_telebu = 0;

      this.Totaltotal_amount_telebu = 0;
      this.TotalTotalBankCharges = 0;
      this.TotalFailedTransactions = 0;
      this.TotalFailedTickets = 0;
      this.TotalFailedAmount = 0;
    },
    getFromToDate() {
      var today = new Date();
      var firstDay = "";
      var lastDay = "";
      // firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      firstDay = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      var firstDay_dd = String(firstDay.getDate()).padStart(2, "0");
      var firstDay_mm = String(firstDay.getMonth() + 1).padStart(2, "0"); //January is 0!
      var firstDay_yyyy = firstDay.getFullYear();

      var lastDay_dd = String(lastDay.getDate()).padStart(2, "0");
      var lastDay_mm = String(lastDay.getMonth() + 1).padStart(2, "0"); //January is 0!
      var lastDay_yyyy = lastDay.getFullYear();

      firstDay = firstDay_yyyy + "-" + firstDay_mm + "-" + firstDay_dd; //2018-06-01
      lastDay = lastDay_yyyy + "-" + lastDay_mm + "-" + lastDay_dd; //2018-06-01

      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = dd + "-" + mm + "-" + yyyy; //2018-06-01
      this.FromDate = today;
      this.detailAccountsData.FromDate = firstDay;
      this.detailAccountsData.ToDate = firstDay;
      // this.detailAccountsData.ToDate = lastDay;
    },
    ExportNovoAccountsExcel() {
      if (this.ExcelArr.length > 0) {
        var dataOne = XLSX.utils.json_to_sheet(this.ExcelArr);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataOne, "Novo_Accounts");
        let ExcelDate = new Date().toDateString();
        if (this.detailAccountsData.FromDate) {
          ExcelDate = new Date(this.detailAccountsData.FromDate).toDateString();
        } else {
          ExcelDate = new Date().toDateString();
        }
        XLSX.writeFile(
          wb,
          `${WANASHAdashboardName.toUpperCase()}_Novo_Accounts_Report_${ExcelDate}.xlsx`
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    getNovoAccountsReport() {
      this.data = [];
      this.data1 = [];
      this.finalArr = [];
      this.ExcelArr = [];
      this.isLoader = true;
      let payload = {
        FromDate: this.detailAccountsData.FromDate,
        ToDate: this.detailAccountsData.ToDate,
        CinemaID: this.detailAccountsData.selectedCinema,
        PaymentMode: this.detailAccountsData.selectedPaymentMode,
        report_type: "internal",
        MovieID: this.detailAccountsData.selectedMovie,
      };

      AccountReportService.getNovoAccountsReport(payload)
        .then((response) => {
          const { data } = response;
          if (
            data.Status &&
            (data.InternalReportsDetails.length > 0 ||
              data.TotalDetails.length > 0)
          ) {
            this.data = data.InternalReportsDetails;
            this.data1 = data.TotalDetails;
            this.records = [];
            if (this.data.length > 0) {
              this.data.forEach((element) => {
                this.records.push(element);
              });
              let finalArr = this.finalArr;
              let NewArr = this.data.map((x) => {
                finalArr.push({
                  "SN.": x["Sr"],
                  "Cinema Name": x["CinemaName"],
                  "Movie Name": x["MovieName"],
                  "Transaction Date": x["TransactionDate"],
                  "Movie Date": x["ShowDate"],
                  "Total Transactions": x["TransactionCount"],
                  "Payment Type": x["PaymentType"],
                  "Total Tickets": x["NoofTicket"],
                  "Paid Amount": x["PaidAmount"],
                  "Basic Ticket price (BTP)": x["BaseTicketPriceInclVAT"],
                  "Bank Charges(BC) on BTP": x["BCONBTP"],
                  "VAT on BC on BTP": x["VATONBCONBTP"],

                  // "Service Charges Novo": x["service_charges_novo"],
                  // "VAT on SC  Novo": x["vat_service_charges_novo"],
                  // "Bank Charges(BC) on SC  Novo": x["bc_service_charges_novo"],
                  // "VAT on BC on SC  Novo": x["vat_bc_service_charges_novo"],

                  // "SCB Convenience Fee": x["SCBConveniencefee"],
                  // "VAT(10%) on SCB Convenience Fee":
                  //   x["VATONSCBConveniencefee"],
                  "Transferable to Novo": x["TotalAmount"],

                  "Service Charges Wanasa Time": x["service_charge_telebu"],
                  "VAT on SC Wanasa Time": x["vat_service_charge_telebu"],
                  "Bank Charges(BC) on SC Wanasa Time": x["bc_service_charge_telebu"],
                  "VAT on BC on SC Wanasa Time": x["vat_bc_service_charge_telebu"],

                  "BC on BTP (Novo Share)": x["BCONBTP"],
                  "VAT on BC on BTP (Novo Share)":
                    x["VATONBCONBTP"],
                    
                  // "BC ON SCB CONVENIENCE FEE": x["bconscb_telebu"],
                  // "VAT ON BC ON SCB CONVENIENCE FEE": x["vatonbconscb_telebu"],

                  "Wanasa Time Total Amount": x["total_amount_telebu"],
                  "Paid to Payment Gateway": x["TotalBankCharges"],
                  "Failed Transactions": x["FailedTransactions"],
                  "Failed Tickets": x["FailedTickets"],
                  "Ticket Transaction Amount": x["FailedAmount"],
                });
              });
              this.ExcelArr = finalArr;
            }
          } else {
            this.showMessageSnackbar(data.Message);
          }
          this.isLoader = false;
        })
        .catch((error) => {
          console.log("Catch on AddUser-getRoles Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetForm() {
      Object.keys(this.detailAccountsData).forEach(
        (key) => (this.detailAccountsData[key] = "")
      );
      this.getFromToDate();
      this.detailAccountsData["selectedCinema"] = 0;
      this.detailAccountsData["selectedPaymentMode"] = "";
      this.detailAccountsData["selectedMovie"] = 0;
    },
    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
  computed: {
    displayedRecords() {
      this.TotalTransactionCount = 0;
      this.TotalNoofTicket = 0;
      this.TotalPaidAmount = 0;
      this.TotalBTP = 0;
      this.TotalBCONBTP = 0;
      this.TotalVATONBCONBTP = 0;
      this.TotalSCBConveniencefee = 0;
      this.TotalVATONSCBConveniencefee = 0;
      this.TotalTotalAmount = 0;

      this.Totalservice_charges_novo = 0;
      this.Totalvat_service_charges_novo = 0;
      this.Totalbc_service_charges_novo = 0;
      this.Totalvat_bc_service_charges_novo = 0;

      this.Totalservice_charge_telebu = 0;
      this.Totalvat_service_charge_telebu = 0;
      this.Totalbc_service_charge_telebu = 0;
      this.Totalvat_bc_service_charge_telebu = 0;

      this.TotalnovoBCONBTP = 0;
      this.TotalnovoVATONBCONBTP = 0;

      this.Totalbconscb_telebu = 0;
      this.Totalvatonbconscb_telebu = 0;

      this.Totaltotal_amount_telebu = 0;
      this.TotalTotalBankCharges = 0;
      this.TotalFailedTransactions = 0;
      this.TotalFailedTickets = 0;
      this.TotalFailedAmount = 0;
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      // return this.records.slice(startIndex, endIndex);
      // console.log("this.records=>", this.records);
      return this.records.slice(startIndex, endIndex);
      //  this.records.slice(startIndex, endIndex);

      var newArray = [];

      this.records.forEach((value) => {
        var exists = false;
        newArray.forEach((val2) => {
          if (value.OrderID == val2.OrderID) {
            exists = true;
          }
        });
        if (exists == false && value.OrderID != "") {
          newArray.push(value);
        }
      });
      this.records = newArray;

      console.log("this.records=>", this.records);

      return newArray;
    },
    getUpdate() {
      // this.callback();
      this.displayedRecords.forEach((element) => {
        this.TotalTransactionCount =
          parseInt(this.TotalTransactionCount) +
          parseInt(element.TransactionCount);
        this.TotalNoofTicket =
          parseInt(this.TotalNoofTicket) + parseInt(element.NoofTicket);
        this.TotalPaidAmount =
          parseFloat(this.TotalPaidAmount) + parseFloat(element.PaidAmount);
        this.TotalBTP = parseFloat(this.TotalBTP) + parseFloat(element.BaseTicketPriceInclVAT);
        this.TotalBCONBTP =
          parseFloat(this.TotalBCONBTP) + parseFloat(element.BCONBTP);
        this.TotalVATONBCONBTP =
          parseFloat(this.TotalVATONBCONBTP) + parseFloat(element.VATONBCONBTP);
        this.TotalSCBConveniencefee =
          parseFloat(this.TotalSCBConveniencefee) +
          parseFloat(element.SCBConveniencefee);
        this.TotalVATONSCBConveniencefee =
          parseFloat(this.TotalVATONSCBConveniencefee) +
          parseFloat(element.VATONSCBConveniencefee);
        this.TotalTotalAmount =
          parseFloat(this.TotalTotalAmount) + parseFloat(element.TotalAmount);

        this.Totalservice_charges_novo =
          parseFloat(this.Totalservice_charges_novo) +
          parseFloat(element.service_charges_novo);
        this.Totalvat_service_charges_novo =
          parseFloat(this.Totalvat_service_charges_novo) +
          parseFloat(element.vat_service_charges_novo);
        this.Totalbc_service_charges_novo =
          parseFloat(this.Totalbc_service_charges_novo) +
          parseFloat(element.bc_service_charges_novo);
        this.Totalvat_bc_service_charges_novo =
          parseFloat(this.Totalvat_bc_service_charges_novo) +
          parseFloat(element.vat_bc_service_charges_novo);

        this.Totalservice_charge_telebu =
          parseFloat(this.Totalservice_charge_telebu) +
          parseFloat(element.service_charge_telebu);
        this.Totalvat_service_charge_telebu =
          parseFloat(this.Totalvat_service_charge_telebu) +
          parseFloat(element.vat_service_charge_telebu);
        this.Totalbc_service_charge_telebu =
          parseFloat(this.Totalbc_service_charge_telebu) +
          parseFloat(element.bc_service_charge_telebu);
        this.Totalvat_bc_service_charge_telebu =
          parseFloat(this.Totalvat_bc_service_charge_telebu) +
          parseFloat(element.vat_bc_service_charge_telebu);

        this.TotalnovoBCONBTP =
          parseFloat(this.TotalnovoBCONBTP) +
          parseFloat(element.BCONBTP);
        this.TotalnovoVATONBCONBTP =
          parseFloat(this.TotalnovoVATONBCONBTP) +
          parseFloat(element.VATONBCONBTP);

        this.Totalbconscb_telebu =
          parseFloat(this.Totalbconscb_telebu) +
          parseFloat(element.bconscb_telebu);
        this.Totalvatonbconscb_telebu =
          parseFloat(this.Totalvatonbconscb_telebu) +
          parseFloat(element.vatonbconscb_telebu);

        this.Totaltotal_amount_telebu =
          parseFloat(this.Totaltotal_amount_telebu) +
          parseFloat(element.total_amount_telebu);
        this.TotalTotalBankCharges =
          parseFloat(this.TotalTotalBankCharges) + parseFloat(element.TotalBankCharges);
        this.TotalFailedTransactions =
          parseFloat(this.TotalFailedTransactions) +
          parseFloat(element.FailedTransactions);
        this.TotalFailedTickets =
          parseFloat(this.TotalFailedTickets) +
          parseFloat(element.FailedTickets);
        this.TotalFailedAmount =
          parseFloat(this.TotalFailedAmount) + parseFloat(element.FailedAmount);
      });
    },
  },
  created() {
    this.records = [];
    if (this.data2.length > 0) {
      this.data2.forEach((element) => {
        this.records.push(element);
      });
    }
  },
};
</script>

<style>
label {
  font-weight: 600;
}
.table_class {
  overflow: auto;
}
td {
  padding: 0px;
}
.table-bordered thead th {
  border: 1px solid white;
}
.pagination_button {
  padding: 8px;
  margin: 2px;
  border-radius: 3px;
  font-size: 1em;
  cursor: pointer;
}
.pagination-row {
  padding: 5px 0;
}

.my-custom-scrollbar {
  position: relative;
  height: 850px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
